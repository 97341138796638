import React from 'react';

function HomeContent() {
  return (
    <div className="bg-black text-white min-h-screen text-sm py-12"> {/* Adjusted overall font size to text-sm */}
      <div className="max-w-4xl mx-auto px-8">
        
        {/* Introduction Section */}
        <section className="pb-10 flex flex-col sm:flex-row items-center justify-center space-x-0 sm:space-x-8 text-left">
          <div className="flex-shrink-0 mb-4 sm:mb-0">
            <img
              src="./content/profile.png"
              alt="Jakub Hojsan"
              className="w-48 h-48 rounded-full"
            />
          </div>
          <div>
            <h1 className="text-3xl font-semibold mb-4">Hey, my name is Jakub Hojsan.</h1> {/* Reduced font size */}
            <p className="text-base mb-2 max-w-2xl"> {/* Adjusted from text-lg to text-base */}
              I am a product manager and founder with a technical background in Computer Science. I have a deep desire to understand my users, and to build off their needs to create positive (and lucrative) outcomes.
            </p>
            <p className="text-base max-w-2xl"> {/* Adjusted from text-lg to text-base */}
              Thanks for stopping by my page to get to know me!
            </p>
          </div>
        </section>

        <hr className="border-t border-gray-700 my-8" />

        {/* Background Story Section */}
        <section>
          <h2 className="text-2xl font-semibold">
            So what molded me to become who I am today?
          </h2>
          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            When I was in high school, I started a marketplace (called Taskway) that aimed to connect students with local odds jobs in their communities. It was my first foyer into entrepreneurship, and it made me extremely strong technically and taught me product sense.
          </p>
          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            Who are my users? What are their jobs to be done? What are their priorities? What should be in my MVP?
          </p>

          {/* Taskway Screenshot */}
          <div className="mt-6">
            <img
              src="./content/Rectangle 6.png"
              alt="Taskway Dashboard"
              className="rounded-lg mx-auto"
            />
            <p className="text-center mt-2 text-sm italic">
              Before all the cool JavaScript frameworks existed, there was PHP.
            </p>
          </div>
        </section>

        <hr className="border-t border-gray-700 my-8" />

        {/* What I Learned Section */}
        <section className="mt-12">
          <h2 className="text-2xl font-semibold">What I learned</h2> {/* Adjusted from text-3xl to text-2xl */}
          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            Like many good things, it failed. But it allowed me to fall forward. It taught me how to fish, and find those answers.
          </p>
          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            I wanted to build more. My friend and I went on to do some fun technical projects, one of which was a smart mirror. We built it with some dielectric glass, a 4GB raspberry pi, some old monitor, and some open-source software.
          </p>

          {/* Smart Mirror Project */}
          <div className="mt-6">
            <img
              src="./content/Rectangle 7.png"
              alt="Smart Mirror Project"
              className="rounded-lg mx-auto"
            />
            <p className="text-center mt-2 text-sm italic">
              POV: You are watching masters at work.
            </p>
          </div>
        </section>

        <hr className="border-t border-gray-700 my-8" />

        {/* My Journey into Product Section */}
        <section className="mt-12">
          <h2 className="text-2xl font-semibold">My journey into product</h2> {/* Adjusted from text-3xl to text-2xl */}
          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            That experience got my foot in the door at Ilio Mirror, a startup that gave me a chance at the CTO role in exchange for a working MVP of a mirror that resembled Lululemon’s recent acquisition. The target audience was families with young to teenage children to capitalize on the era of Tiktok dancing tutorials. The market shift killed the product, and lost the niche position it had within the early smart mirror market.
          </p>

          {/* Ilio Mirror Video */}
          <div className="mt-4">
            <video width="100%" controls poster="./content/poster.png">
              <source src="./content/ilio.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            It took me weeks to make this thing, and then we drove it to Pasadena to film a commercial with the founders to have something to show to investors and try to prove we found market fit. (We hadn’t)
          </p>

          {/* Heavy Mirror */}
          <div className="mt-6">
            <img
              src="./content/Rectangle 9.png"
              alt="Smart Mirror Project Ilio"
              className="rounded-lg mx-auto"
            />
            <p className="text-center mt-2 text-sm italic">
              The damn thing was made of metal/wood and was heavy.
            </p>
          </div>

          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            But it taught me many lessons about product like how to best communicate with future users, and how important it is to meet stakeholder requirements. It gave me a newfound appreciation of product designers and other engineers that have done amazing things with this technology.
          </p>

          {/* Logos Section */}
          <div className="flex justify-center space-x-6 my-8">
            <img
              src="./content/UCLA_Bruins_logo.svg 1.png"
              alt="UCLA"
              className="h-16"
            />
            <img
              src="./content/microsoft.png"
              alt="Microsoft"
              className="h-16"
            />
          </div>

          <p className="mt-4 text-base"> {/* Adjusted from text-lg to text-base */}
            In 2023, I graduated from UCLA with a Computer Science degree. Shortly after, I started working as a product manager at Microsoft, focusing on infrastructure tooling.
          </p>
        </section>
      </div>
    </div>
  );
}

export default HomeContent;
