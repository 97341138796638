import React from 'react';

function Contact() {
  return (
    <div className="bg-black min-h-screen flex items-center justify-center text-white">
      <div className="text-center">
        <h1 className="text-4xl font-semibold mb-6">Contact Me</h1>
        <p className="text-lg mb-4">
          Feel free to reach out via LinkedIn or email!
        </p>
        <div className="space-y-4">
          <a
            href="https://www.linkedin.com/in/jakubhojsan"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white text-lg transition-colors duration-200 block"
          >
            LinkedIn
          </a>
          <a
            href="mailto:jakubhojsan@gmail.com"
            className="text-gray-300 hover:text-white text-lg transition-colors duration-200 block"
          >
            jakubhojsan@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
