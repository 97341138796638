import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const navigationLinks = [
    { name: 'Resume', href: '/content/resume.pdf', external: true },  // Point to resume link
    { name: 'Contact', href: '/contact', external: false },           // Contact page route
  ];

  return (
    <header className="bg-black w-full">
      <div className="max-w-6xl mx-auto px-4 py-6 flex justify-between items-center">
      <Link to="/" className="text-3xl text-white font-semibold">
        <h1 className="text-3xl text-white font-semibold">Jakub Hojsan</h1>
      </Link>

        <div className="sm:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="text-gray-300 hover:text-white focus:outline-none focus:text-white transition-colors duration-200"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Navigation Links */}
        <nav className="hidden sm:flex space-x-6">
          {navigationLinks.map((link) =>
            link.external ? (
              <a
                key={link.name}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white text-lg transition-colors duration-200"
              >
                {link.name}
              </a>
            ) : (
              <Link
                key={link.name}
                to={link.href}
                className="text-gray-300 hover:text-white text-lg transition-colors duration-200"
              >
                {link.name}
              </Link>
            )
          )}
        </nav>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <nav className="sm:hidden px-4 pb-6 transition-all duration-300 ease-in-out">
          {navigationLinks.map((link) =>
            link.external ? (
              <a
                key={link.name}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-gray-300 hover:text-white text-lg mb-4 transition-colors duration-200"
              >
                {link.name}
              </a>
            ) : (
              <Link
                key={link.name}
                to={link.href}
                className="block text-gray-300 hover:text-white text-lg mb-4 transition-colors duration-200"
              >
                {link.name}
              </Link>
            )
          )}
        </nav>
      )}
    </header>
  );
}

export default Header;
