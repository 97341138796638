import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // Import Routes instead of Switch
import Header from './Header';
import HomeContent from './HomeContent';
import Contact from './Contact';  // Import Contact component

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomeContent />} />  {/* Use element prop */}
        <Route path="/contact" element={<Contact />} />  {/* Use element prop */}
      </Routes>
    </Router>
  );
}

export default App;
